import React from "react";
import { connect } from "react-redux";
import Octicon from "react-octicon";
import { DropzoneButton } from "traec-react/utils/documentUpload/dropZone";
import Traec from "traec";
import { HTMLText } from "traec/utils/html";
import { Tooltip } from "react-tippy";
import { BSBtn, BSBtnDropdown } from "traec-react/utils/bootstrap";
import DocumentHistoryRows from "./documentHistoryRows";
import { getNodeFromPath, getPathChildren } from "traec/utils/nodes";
import chroma from "chroma-js";
import Moment from "moment";
import { ReportDocumentFormButton } from "./reportDocumentForm";
import { ErrorBoundary } from "traec-react/errors/handleError";
import { confirmDelete } from "traec-react/utils/sweetalert";
import { deleteDocumentObject } from "./documentHistoryRows";

export function DocumentTitleTooltip({ doc, indentLevel }) {
  let title = doc ? doc.getInPath("description.title") || doc.get("name").substring(0, 8) : null;

  return (
    <div style={{ margin: "0", marginLeft: `${(indentLevel + 1) * 1.5}em` }}>
      {title}
      <Tooltip
        animateFill={false}
        html={<HTMLText extraClassName="text-left" text={doc.getIn(["description", "text"])} />}
      >
        <Octicon name="info" className="ml-2" />
      </Tooltip>
    </div>
  );
}

export const downloadS3Object = ({ trackerId, commitId, docId, currentDocObject }) => {
  let _fetch = new Traec.Fetch("tracker_commit_document_object", "retrieve", {
    trackerId,
    commitId,
    docId,
    docObjectId: currentDocObject.get("uid"),
    signedURL: true
  });
  _fetch.updateFetchParams({
    throttleTimeCheck: 1,
    cacheTime: 1,
    postSuccessHook: data => {
      //console.log("Got file data", data);
      if (data.signed_url) {
        //console.log("Downloading file", data.signed_url);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = data.signed_url;
        a.download = data.filename || "unknown.file";
        document.body.appendChild(a);
        a.click();
      }
    }
  });
  _fetch.dispatch();
};

const fetchAssignToMetricValues = ({ trackerId, commitId, doc, currentDocObject }) => {
  let fetch = new Traec.Fetch("tracker_dispatch", "post", { trackerId });
  fetch.updateFetchParams({
    body: {
      type: "CSV_TO_INPUT_VALUES",
      payload: {
        commitId,
        fileId: currentDocObject.get("uid"),
        docId: doc.get("uid")
      }
    },
    headers: { "content-type": "application/json" },
    rawBody: false,
    postSuccessHook: e => window.location.reload()
  });
  fetch.dispatch();
};

export function AssignToMetricValues(props) {
  let { currentDocObject, doc } = props;
  if (!currentDocObject) {
    return null;
  }

  if (currentDocObject?.get("document") === doc?.get("uid")) {
    if (!doc?.getInPath("meta_json.assignments")?.size) {
      console.log(
        "No assignments. Skipping auo-fill button render for document:",
        doc?.get("uid"),
        doc?.getInPath("description.title")
      );
      return null;
    }
  }

  return (
    <button
      className="btn btn-sm btn-danger pt-0 pb-0 ml-1 mr-1"
      onClick={e => {
        fetchAssignToMetricValues(props);
      }}
    >
      Auto-fill report from data provided
    </button>
  );
}

export function CurrentObject(props) {
  let { currentDocObject, errors, status } = props;

  if (!currentDocObject) {
    return <span className="float-left">No file</span>;
  }

  if (status && status === "sent") {
    return <span className="alert-warning float-left">Uploading... please wait.</span>;
  }

  if (errors || status === "failed") {
    //console.log("ERRORS IN DOCUMENT UPLOAD", errors);
    if (errors === "Request Entity Too Large") {
      errors = "Document too large! Maximum allowed upload size is 20Mb";
    } else {
      errors = "Error in upload.  Maximum allowed upload size is 20Mb";
    }
    return <span className="alert-danger float-left">{errors}</span>;
  }

  let filename = currentDocObject ? currentDocObject.get("filename") : null;
  let url = currentDocObject ? currentDocObject.get("signed_url") : null;

  let isUploaded = currentDocObject ? !(currentDocObject.created && currentDocObject.virus_checked) : false;

  return (
    <ErrorBoundary>
      {isUploaded ? <Octicon name="check" className="float-left" /> : <Octicon name="file" />}
      <AssignToMetricValues {...props} />
      <a
        style={{ cursor: "pointer" }}
        onClick={e => {
          e.preventDefault();
          downloadS3Object({ ...props });
        }}
      >
        {filename}
      </a>
    </ErrorBoundary>
  );
}

class ReportDocumentRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFiles: [],
      uploadedFile: false,
      showHistory: false
    };

    this.confirmDocumentUpload = this.confirmDocumentUpload.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onCancelUpload = this.onCancelUpload.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.clearFile = this.clearFile.bind(this);
  }

  getUrlParams() {
    let { cref, commitId, treeId, doc } = this.props;
    let pathId = doc ? doc.get("path") : doc;
    let trackerId = cref.get("tracker");
    let refId = cref.get("uid");
    return { trackerId, refId, commitId, treeId, pathId };
  }

  uploadFile(docId) {
    //e.preventDefault();
    let { trackerId, commitId, refId, path: pathId } = this.props;
    let fetch = new Traec.Fetch("tracker_node", "put", {
      trackerId,
      commitId,
      refId,
      pathId,
      allow_commit_change: true
    });
    // Add the file object to the form and dispatch
    let formData = new FormData();
    formData.append("fileobj", this.state.selectedFiles[0]);
    formData.append("type", "document");
    formData.append("path", pathId);
    //fetch.updateFetchParams({ body: formData });
    // You can send this as a raw file if you set:
    // headers: { "content-type": null: "content-disposition": "attachment; filename=upload.jpg" },
    // body: this.state.selectedFiles[0]
    fetch.updateFetchParams({
      headers: { "content-type": null },
      rawBody: true,
      body: formData
    });
    fetch.dispatch();
    // Set the state here
    this.setState({
      uploadedFile: this.state.selectedFiles[0].name,
      selectedFiles: []
    });
  }

  clearFile() {
    let { docStatus, trackerId, commitId, refId, docId, currentDocObject } = this.props;

    // This actually deletes the current uploaded document
    //let docObjectId = currentDocObject.get("uid")
    //return deleteDocumentObject({ trackerId, commitId, docId, docObjectId })

    // This just clears the current status
    let pathId = docStatus.get("_path");
    let fetch = new Traec.Fetch("tracker_node", "patch", {
      trackerId,
      commitId,
      refId,
      pathId
    });
    fetch.updateFetchParams({
      body: {
        type: "documentstatus",
        node: {
          documentstatus: {
            current_object: null
          }
        }
      }
    });
    confirmDelete({
      text: `This will clear the current uploaded document.  It will still appear in the upload history.  Would you like to proceed?`,
      onConfirm: () => {
        fetch.dispatch();
      }
    });
  }

  confirmDocumentUpload(e, docId) {
    e.preventDefault();

    if (this.state.selectedFiles) {
      this.uploadFile(docId);
    }
  }

  onDrop(files) {
    this.setState({ selectedFiles: files });
  }

  onCancelUpload() {
    this.setState({ selectedFiles: [] });
  }

  renderSelectFile() {
    let { doc } = this.props;

    if (this.props.disableInputs) {
      return null;
    }

    let files = this.state.selectedFiles.map(file => (
      <a key={file.name}>
        Upload {file.name}? ({(file.size / 1e6).toFixed(1)}Mb)
      </a>
    ));

    // Give a warning if the file is too large
    let confirmButton = null;
    if (this.state.selectedFiles.filter(file => file.size / 1e6 > 20).length) {
      files = [
        <span key={0} className="alert-danger">
          Maximum allowed upload size is 20Mb
        </span>
      ];
    } else {
      confirmButton = (
        <BSBtn
          text={"Upload"}
          onClick={e => this.confirmDocumentUpload(e, doc ? doc.get("uid") : null)}
          extra_className="pl-1 pr-1 m-0 p-0"
          noFloatRight={true}
        />
      );
    }

    return (
      <div className="float-right">
        <DropzoneButton
          onDrop={this.onDrop}
          extra_className="pl-1 pr-1 m-0 p-0"
          selectAreaText="Select file"
          confirmButton={confirmButton}
          selectedFiles={files}
          onCancelUpload={this.onCancelUpload}
        />
      </div>
    );
  }

  render() {
    let { doc, rowColor, indentLevel, trackerId, commitId, path, currentDocObject, disableInputs } = this.props;
    if (!doc) {
      return null;
    }

    let isForm = doc.getInPath("meta_json.input_type") == "form";

    if (isForm) {
      return (
        <ErrorBoundary>
          <ReportDocumentFormButton
            readOnly={disableInputs}
            doc={doc}
            currentDocObject={currentDocObject}
            rowColor={rowColor}
            indentLevel={indentLevel}
            trackerId={trackerId}
            commitId={commitId}
            path={path}
          />
        </ErrorBoundary>
      );
    }

    return (
      <React.Fragment>
        <tr style={{ backgroundColor: rowColor }}>
          <td className="border-0">
            <DocumentTitleTooltip doc={doc} indentLevel={indentLevel} />
          </td>

          <td colSpan={6} className="border-0">
            <CurrentObject {...this.props} />

            <BSBtnDropdown
              links={[
                {
                  name: `${this.state.showHistory ? "Hide" : "Show"} History`,
                  onClick: e => {
                    this.setState({ showHistory: !this.state.showHistory });
                  }
                },
                disableInputs
                  ? {}
                  : {
                      name: `Clear current upload`,
                      onClick: e => {
                        this.clearFile();
                      }
                    }
              ]}
            />
            <span className="float-right">&nbsp; | &nbsp;</span>
            {this.renderSelectFile()}
          </td>
        </tr>
        {this.state.showHistory ? (
          <DocumentHistoryRows
            {...this.props}
            hide={!this.state.showHistory}
            docId={doc ? doc.get("uid") : null}
            path={path}
            indentLevel={indentLevel + 1}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const get_color = (commit, lastUpdate) => {
  if (!lastUpdate || !commit) {
    return null;
  }
  let color = null;
  let firstCommit = commit.getInPath("meta_json.history.0.updateOn");
  if (lastUpdate && firstCommit) {
    if (Moment(lastUpdate).diff(Moment(firstCommit)) > 0) {
      color = chroma("#bf80ff")
        .brighten(2)
        .hex();
    }
  }
  return color;
};

const mapStateToProps = (state, ownProps) => {
  let { trackerId, commitId, path, commitNodes } = ownProps;

  // Get the documeent and description
  let doc = getNodeFromPath(state, path, commitNodes);
  let docId = doc ? doc.get("uid") : null;

  // Nest the description inside of the doc object
  let description = getPathChildren(state, path, commitNodes, "descriptions").first() || Traec.Im.Map();
  doc = doc ? doc.set("description", description) : doc;

  // Get the status and current object
  let docStatus = getPathChildren(state, path, commitNodes, "documentstatus").first() || Traec.Im.Map();
  let docObject = docStatus ? docStatus.get(`current_object`) : null;

  // Get the currentDocObject
  let currentDocObject = false;
  currentDocObject = docObject
    ? docObject.get("commit") === commitId
      ? docObject.merge(state.getInPath(`entities.docObjects.byId.${docObject.get("uid")}`) || Traec.Im.Map())
      : null
    : null;

  // Get the cache response and any errors
  let fetch = new Traec.Fetch("tracker_commit_document", "put", {
    trackerId,
    commitId,
    documentId: docId,
    allow_commit_change: true
  });
  let fetchCache = fetch.redux_cache_object;
  let errors = fetchCache ? fetchCache.get(`errors`) : null;
  let status = fetchCache ? fetchCache.get(`status`) : null;

  // Get the color of this row
  let rowColor = get_color(
    state.getInPath(`entities.commits.byId.${commitId}`),
    currentDocObject ? currentDocObject.get("created") : null
  );

  return { currentDocObject, docStatus, rowColor, errors, status, fetchCache, doc, docId };
};

export default connect(mapStateToProps)(ReportDocumentRow);
